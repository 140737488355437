import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'
import { push } from 'redux-first-history'

import { updateRegistrationGenderAction } from 'actions/form/stepRegistrationAction'
import { Gender } from 'common-constants/api6.types'
import { ButtonsBlock } from 'components/designSystem/Button/ButtonsBlock'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { OnboardingLayout } from 'components/designSystem/layout/OnboardingLayout/OnboardingLayout'
import { ManSvg } from 'components/designSystem/svgr/ManSvg'
import { WomanSvg } from 'components/designSystem/svgr/WomanSvg'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { enterNamePath } from './paths'
import { StepCommonProps } from './types'

export const SelectYourGenderStep: FC<StepCommonProps> = () => {
  const dispatch = useAppDispatch()
  const {
    locale,
  } = useShallowEqualSelector(({ systemReducer: { locale } }) => ({ locale }))

  const handleSelectGender = (value: Gender) => {
    dispatch(updateRegistrationGenderAction(value))
    dispatch(push(mergeAllUrls(locale, enterNamePath)))
  }
  const handleSelectFemale = () => {
    handleSelectGender(Gender.Female)
  }
  const handleSelectMale = () => {
    handleSelectGender(Gender.Male)
  }

  return (
    <OnboardingLayout
      title={
        <FormattedMessage
          id="app.lets.get.acquainted"
          defaultMessage="Привет, давай знакомиться"
        />
      }
      subtitle={
        <FormattedMessage
          id="app.tell.about.yourself"
          defaultMessage="Расскажи о себе — это поможет создать профиль и сразу начать общаться."
        />
      }
    >
      <ButtonsBlock>
        <PrimaryButton
          color="white"
          border={false}
          onClick={handleSelectMale}
          data-name="male-gender-action"
        >
          <ManSvg />
          <ButtonText>
            <FormattedMessage id="app.i.am.man" defaultMessage="Я мужчина" />
          </ButtonText>
        </PrimaryButton>
        <PrimaryButton
          color="white"
          border={false}
          onClick={handleSelectFemale}
          data-name="female-gender-action"
        >
          <WomanSvg />
          <ButtonText>
            <FormattedMessage id="app.i.am.girl" defaultMessage="Я девушка" />
          </ButtonText>
        </PrimaryButton>
      </ButtonsBlock>
    </OnboardingLayout>
  )
}

const ButtonText = styled.div`
  html[dir='ltr'] & {
    margin-left: var(--spacing-8px, 8px);
  }
  html[dir='rtl'] & {
    margin-right: var(--spacing-8px, 8px);
  }
`
